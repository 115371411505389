<template>
  <v-row class="h-screen flex bg-img">
    <v-col
      cols="12"
      sm="6"
      md="8"
      lg="3"
      xl="6"
      class=" flex items-center justify-center  mx-auto text-center"
    >
      <p class="mt-10 " style="font-size:40px">
        {{ $t("notauthorize") }}
      </p>
      <p class="mt-5">
        {{ $t("notauthorizeText") }}
      </p>
      <v-btn class="ma-5" color="primary" to="/">
        {{ $t("backToHome") }}
      </v-btn>
      <v-btn class="" color="success" to="/auth">
        {{ $t("login.signIn") }}
      </v-btn>

      <img
        src="../assets/Asset 2.svg"
        alt="graphic-not-authorized"
        class="mx-auto my-4 d-block"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {};
</script>

<style></style>
